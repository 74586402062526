<template>
  <tr>
    <td></td>
    <td>
      <v-text-field
        v-model="filterItem.name_tc__startswith"
        outlined
        dense
        clearable
        @change="onChange"
        :clear-icon-cb="onChange"
      ></v-text-field>
    </td>
    <td></td>
    <td></td>
  </tr>
</template>
<script>
import BaseFilter from "./BaseFilter";
export default {
  data: (vm) => ({}),
  mixins: [BaseFilter],
  methods: {},
};
</script>