<template>
  <v-container
    fluid
    tag="section"
  >
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <DeliverTypeDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
      v-on:onCreatePrimaryType="createPrimaryType"
      v-on:onCreateSecondaryType="createSecondaryType"
      :new_tax_primary_type="new_tax_primary_type"
      :new_tax_secondary_type="new_tax_secondary_type"
      :unit_items="unit_items"
      :tax_primary_types="tax_primary_types"
      :tax_secondary_types="tax_secondary_types"
    ></DeliverTypeDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('commodity.deliver-type-list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:header>
          <DeliverTypeFilter v-on:onFilterChange="onFilterChange"></DeliverTypeFilter>
        </template>
        <template v-slot:item.name="{ item }">
          {{ get_deliver_type_name(item) }}
        </template>
        <template v-slot:item.extra_fee="{ item }">
          <template v-for="data in parse_extra_fee(item)">
            <div>{{ data }}</div>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="editItem(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="addRules(item)"
              >mdi-plus</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="disableItem(item,$t('commodity.deliver-type'),item.name_tc)"
              >mdi-block-helper</v-icon>
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import DeliverTypeDialog from "../dialogs/DeliverTypeDialog";
import BaseTable from "./BaseTable.vue";
import { action_items, unit_items } from "@/definition.js";
import DeliverTypeFilter from "../filters/DeliverTypeFilter";
export default {
  data: (vm) => ({
    url: "/commodity/deliver-type/",
    headersMap: {
      id:{
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      name_tc:{
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name_tc",
      },
      name_sc:{
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name_sc",
      },
      extra_fee:{
        sortable: true,
        text: vm.$i18n.t("commodity.extra-fee"),
        value: "extra_fee",
      },
      actions:{
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    },
    tcHeaders: ['id', 'name_tc', 'extra_fee',  'actions'],
    scHeaders: ['id', 'name_sc', 'extra_fee',  'actions'],
    action_items: action_items,
    new_tax_primary_type: {},
    new_tax_secondary_type: {},
    unit_items: unit_items,
    tax_primary_types: [],
    tax_secondary_types: [],
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    DeliverTypeDialog,
    DeliverTypeFilter,
    CommonAlertDialog,
  },
  methods: {
    createItem(item) {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = this.action_items.create;
      this.showDialog = true;
    },
    editItem(item) {
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      this.action = action_items.edit;
      this.showDialog = true;
    },
    get_deliver_type_name(item) {
      return this.getHanText(item, "name");
    },
    createPrimaryType(data) {
      this.postApi(
        data,
        "/commodity/tax-primary-type/",
        this.setNewTaxPrimaryType
      );
    },
    setNewTaxPrimaryType(action, responseData) {
      this.new_tax_primary_type = responseData;
    },
    createSecondaryType(data) {
      console.log("createSecondaryType");
      this.postApi(
        data,
        "/commodity/tax-secondary-type/",
        this.setNewTaxSecondaryType
      );
    },
    setNewTaxSecondaryType(action, responseData) {
      console.log("setNewTaxSecondaryType");
      this.new_tax_secondary_type = responseData;
    },
    getTaxType() {
      this.getApi("/commodity/tax-primary-type/", this.setTaxPrimaryType);
      this.getApi("/commodity/tax-secondary-type/", this.setTaxSecondaryType);
    },
    setTaxPrimaryType(data) {
      this.tax_primary_types = data.results;
    },
    setTaxSecondaryType(data) {
      this.tax_secondary_types = data.results;
    },
    parse_extra_fee(item) {
      let extra_fee = JSON.parse(item.extra_fee);
      if (extra_fee) {
        var datas = [];
        for (const [key, data] of Object.entries(extra_fee)) {
          var pt = this.tax_primary_types.find(obj=>{
            return obj.id === data.tax_primary_type
          })
          var st = this.tax_secondary_types.find(obj=>{
            return obj.id === data.tax_secondary_type
          })
          var ui = this.unit_items.find(obj=>{
            return obj.value === data.unit
          })
          datas.push(pt?pt.name_tc:'' + " " + st?st.name_tc:'' + " $" + data.unit_fee + "/" + this.$t(ui.text));
        }
        return datas
      }
    },
  },
  mounted() {
    this.getParams["enable"] = true;
    this.getTaxType();
  },
};
</script>
