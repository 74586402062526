<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <TaxPrimaryTypeDialog
      :show="showPrimaryTypeDialog"
      :action="dialogAction"
      :item="primaryTypeDialogItem"
      v-on:onClose="onPrimaryTypeDialogClose"
      v-on:onSave="onPrimaryTypeDialogSave"
    ></TaxPrimaryTypeDialog>
    <TaxSecondaryTypeDialog
      :show="showSecondaryTypeDialog"
      :action="dialogAction"
      :item="secondaryTypeDialogItem"
      v-on:onClose="onSecondaryTypeDialogClose"
      v-on:onSave="onSecondaryTypeDialogSave"
    ></TaxSecondaryTypeDialog>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col v-if="isTraditional">
              <v-text-field
                v-model="item.name_tc"
                :label="$t('name_tc')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('name_tc') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col v-else>
              <v-text-field
                v-model="item.name_sc"
                :label="$t('name_sc')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('name_sc') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-text-field
                v-model="item.default_deliver_fee"
                :label="$t('commodity.default-deliver-fee')"
                type="number"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('commodity.default-deliver-fee') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row> -->
          <v-card>
            <v-row>
              <v-col>{{ $t('commodity.extra-fee') }}</v-col>
            </v-row>
            <template v-for="(extra_item, index) in extra_fee">
              <v-row class="mr-4">
                <v-col>
                  <CommonAutocomplete
                    v-model="extra_item.tax_primary_type"
                    :label="$t('commodity.tax-primary-type')"
                    :placeholder="''"
                    url="/commodity/tax-primary-type/"
                    :item_text="nameField"
                    item_value="id"
                    :query_field="nameFieldFilter"
                    :extra_filter="extra_item"
                    :filter_fields="{}"
                    :cache="tax_primary_type_cache"
                    :no_data_action="true"
                    v-on:noDataAction="triggerCreateTaxPrimaryType"
                    :index="index"
                    v-on:focus="updateIndex"
                    :extra_condition="{
                      enable: true
                    }"
                  ></CommonAutocomplete>
                </v-col>
                <v-col>
                  <CommonAutocomplete
                    v-model="extra_item.tax_secondary_type"
                    :label="$t('commodity.tax-secondary-type')"
                    :placeholder="''"
                    url="/commodity/tax-secondary-type/"
                    :item_text="nameField"
                    item_value="id"
                    :query_field="nameFieldFilter"
                    :extra_filter="extra_item"
                    :filter_fields="{}"
                    :cache="tax_secondary_type_cache"
                    :no_data_action="true"
                    v-on:noDataAction="triggerCreateTaxSecondaryType"
                    :index="index"
                    v-on:update="updateIndex"
                    :extra_condition="{
                      enable: true
                    }"
                  ></CommonAutocomplete>
                </v-col>
                <v-col>
                  <v-select
                    v-model="extra_item.unit"
                    :label="$t('commodity.unit')"
                    :items="unit_items"
                    :item-text="item => $t(item.text)"
                  >
                    <!-- <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('commodity.unit') }}
                    </template> -->
                  </v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="extra_item.unit_fee"
                    :label="$t('commodity.unit-price')"
                  >
                  </v-text-field>
                </v-col>
                <v-icon @click="deleteRule()">mdi-close</v-icon>
              </v-row>
            </template>
            <v-btn @click="addRule()">增加規則</v-btn>
          </v-card>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import TaxPrimaryTypeDialog from "../dialogs/TaxPrimaryTypeDialog";
import TaxSecondaryTypeDialog from "../dialogs/TaxSecondaryTypeDialog";
export default {
  props: {
    new_tax_primary_type: {},
    new_tax_secondary_type: {},
    unit_items: [],
    tax_primary_types: [],
    tax_secondary_types: [],
  },
  data: (vm) => ({
    topic: vm.$i18n.t("commodity.deliver-type"),
    extra_fee: [],
    dialogAction: null,
    showPrimaryTypeDialog: false,
    showSecondaryTypeDialog: false,
    defaultItem: {},
    primaryTypeDialogItem: {},
    secondaryTypeDialogItem: {},
    tax_primary_type: null,
    tax_primary_type_cache: [],
    tax_secondary_type: null,
    tax_secondary_type_cache: [],
    active_index: null,
  }),
  computed: {
    allowSave() {
      return !(
        (this.item.name_tc || this.item.name_sc)
      );
    },
  },
  components: {
    CommonAutocomplete,
    TaxPrimaryTypeDialog,
    TaxSecondaryTypeDialog,
  },
  mixins: [BaseDialog],
  methods: {
    addRule() {
      console.log(this.extra_fee)
      this.extra_fee.push({});
    },
    deleteRule(index) {
      this.extra_fee.splice(index, 1);
    },
    save() {
      console.log("dialog save");
      let editedItem = this._.cloneDeep(this.item);
      // check empty
      const filledData = this.extra_fee.filter(ele => {
        if (Object.keys(ele).length !== 0) {
          return true
        }
        return false
      })

      editedItem.extra_fee = JSON.stringify(filledData);
      this.resetField();
      this.$emit("onSave", editedItem);
    },
    close() {
      this.resetField();
      this.$emit("onClose");
    },
    triggerCreateTaxPrimaryType(primary_type) {
      this.createTaxPrimaryType(primary_type);
    },
    createTaxPrimaryType(primary_type = null) {
      this.primaryTypeDialogItem = this._.cloneDeep(this.defaultItem);
      if (primary_type) {
        var target_field = this.isTraditional ? "name_tc" : "name_sc";
        this.primaryTypeDialogItem[target_field] = primary_type;
      }
      this.dialogAction = this.action_items.create;
      this.showPrimaryTypeDialog = true;
    },
    triggerCreateTaxSecondaryType(secondary_type) {
      this.createTaxSecondaryType(secondary_type);
    },
    createTaxSecondaryType(secondary_type = null) {
      this.secondaryTypeDialogItem = this._.cloneDeep(this.defaultItem);
      if (secondary_type) {
        var target_field = this.isTraditional ? "name_tc" : "name_sc";
        this.secondaryTypeDialogItem[target_field] = secondary_type;
      }
      this.dialogAction = this.action_items.create;
      this.showSecondaryTypeDialog = true;
    },
    onPrimaryTypeDialogClose() {
      this.showPrimaryTypeDialog = false;
    },
    onPrimaryTypeDialogSave(item) {
      this.showPrimaryTypeDialog = false;
      this.$emit("onCreatePrimaryType", item);
    },
    onSecondaryTypeDialogClose() {
      this.showSecondaryTypeDialog = false;
    },
    onSecondaryTypeDialogSave(item) {
      this.showSecondaryTypeDialog = false;
      this.$emit("onCreateSecondaryType", item);
    },
    resetField() {
      this.tax_primary_type = null;
      this.tax_primary_type_cache = null;
      this.tax_secondary_type = null;
      this.tax_secondary_type_cache = null;
      this.extra_fee = []
    },
    updateIndex(index = 0) {
      this.active_index = index;
    },
  },
  watch: {
    new_tax_primary_type(val, oldVal) {
      this.extra_fee[this.active_index]["tax_primary_type"] = val.id;
      this.tax_primary_type_cache.push(val);
    },
    new_tax_secondary_type(val, oldVal) {
      this.extra_fee[this.active_index]["tax_secondary_type"] = val.id;
      this.tax_secondary_type_cache.push(val);
    },
    "item.extra_fee"(val, oldVal){
      if (val) {
        this.extra_fee = JSON.parse(val)
      }
      
    },
    show(val, oldVal){
      this.tax_primary_type_cache = this.tax_primary_types
      this.tax_secondary_type_cache = this.tax_secondary_types
    },
  },
  mounted() {},
};
</script>
